import { useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import instance from "../api/instrance";
import { useToastState } from "../context";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../components";
import { UIContext } from "../context/ui";

export const ResetPassword = () => {
  const { dispatch } = useToastState();
  const { loading, setLoading } = useContext(UIContext);
  const [key, setKey] = useState("");
  const [password, setPassword] = useState("");
  const [hasMinLength, setHasMinLength] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (queryParams.get("key")) {
      setKey(queryParams.get("key"));
    }
  }, []);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Validation conditions
    setHasMinLength(newPassword.length >= 8);
    setHasLowercase(/[a-z]/.test(newPassword));
    setHasUppercase(/[A-Z]/.test(newPassword));
    setHasNumber(/\d/.test(newPassword));
  };

  const handleReset = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    if (!hasMinLength || !hasLowercase || !hasUppercase || !hasNumber) {
      dispatch({
        type: "ERROR",
        payload: "Password must meet all validation criteria.",
      });
      setLoading(false);
      return;
    }

    try {
      const res = await instance.post("/user/reset", {
        password1: formData.get("passwrod1"),
        password2: formData.get("passwrod2"),
        key: key,
      });
      dispatch({ type: "SUCCESS", payload: res.data?.message });
      e.target.reset();
      navigate("/login");
    } catch (err) {
      dispatch({ type: "ERROR", payload: err.response.data.message });
    }
    setLoading(false);
  };

  return (
    <div className="relative w-full flex justify-center min-h-screen overflow-hidden">
      <div className="w-[40%] bg-secondary h-screen p-[40px] md:flex md:flex-col md:justify-between hidden">
        <div></div>
        <div className="flex flex-col gap-4">
          <h2 className="text-white font-inter text-2xl font-bold leading-7 text-left">
            Welcome to IJT Reporting
          </h2>
          <p className="text-white font-inter text-base font-normal leading-7 text-left">
            Get better view of your activities and manage your nazims in an easy
            way. Report your activities here.
          </p>
        </div>
      </div>
      <div className="w-full md:w-[60%] p-6 m-auto lg:max-w-lg">
        <div className="w-full flex items-center justify-center">
          <img src="/logo.png" className="h-[104px] w-[142px]" alt="LOGO" />
        </div>
        <form
          className="space-y-4 bg-white rounded-md shadow-md p-4"
          onSubmit={handleReset}
        >
          <div className="relative">
            <label className="label">
              <span className="text-heading font-inter text-[14px] leading-5">
                Password
              </span>
            </label>
            <input
              type={showPassword ? "text" : "password"}
              name="passwrod1"
              placeholder="Password"
              className="w-full focus:border-innerAlignment text-secondaryText border outline-none border-inputBorder rounded p-2 text-[16px] leading-6 font-inter"
              onChange={handlePasswordChange}
              value={password}
            />
            <span
              className="absolute right-3 cursor-pointer"
              style={{ top: "65%" }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          {/* Password Validation Messages */}
          <div className="flex flex-col gap-1 ml-4">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={hasMinLength}
                readOnly
              />
              <span
                className={hasMinLength ? "text-green-800" : "text-destructive"}
              >
                At least 8 characters
              </span>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={hasLowercase}
                readOnly
              />
              <span
                className={hasLowercase ? "text-green-800" : "text-destructive"}
              >
                At least one lowercase letter
              </span>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={hasUppercase}
                readOnly
              />
              <span
                className={hasUppercase ? "text-green-800" : "text-destructive"}
              >
                At least one uppercase letter
              </span>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={hasNumber}
                readOnly
              />
              <span
                className={hasNumber ? "text-green-800" : "text-destructive"}
              >
                At least one number
              </span>
            </div>
          </div>

          <div className="relative">
            <label className="label">
              <span className="text-heading font-inter text-[14px] leading-5">
                Confirm Password
              </span>
            </label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="passwrod2"
              placeholder="Confirm Password"
              className="w-full text-secondaryText border outline-none border-inputBorder rounded p-2 text-[16px] leading-6 font-inter"
            />
            <span
              className="absolute right-3 cursor-pointer"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={{ top: "65%" }}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          <div>
            <button
              disabled={loading}
              className="text-[14px] leading-6 font-medium font-inter text-white bg-primary w-full py-2 border rounded"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
      </div>
      {loading && <Loader />}
    </div>
  );
};
